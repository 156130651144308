import React, { Dispatch, useState } from "react";
import { Form, Select, Typography } from "antd";
import type { SelectProps } from "antd";
import axios from "axios";
import { mainUrl } from "../../helpers/constants";
import {
  SettingsThunkType,
  createBrandAppliance,
  setBrandAppliancesToAdd,
} from "../../store/actions/categoriesActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../store";
import { LoadingOutlined } from "@ant-design/icons";

let currentValue: string;

const fetch = async (value: string, callback: Function) => {
  currentValue = value;

  if (value) {
    const instance = axios.create({
      baseURL: mainUrl,
      headers: { "Content-Type": "application/json" },
    });
    if (currentValue === value && value.length > 1) {
      const res = await instance.get<any>(`api/v1/brand/sug?q=${value}`);
      if (res.data.status === "success") {
        const data = res.data.brands.map((item: any) => ({
          value: item[1],
          text: item[0],
        }));
        callback(data);
      }
    }
  } else {
    callback([]);
  }
};

const SearchBrand: React.FC<{
  placeholder: string;
  //style: React.CSSProperties;
  currentSearchParams?: { [param: string]: string };
}> = (props) => {
  const { Link } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch() as Dispatch<SettingsThunkType | AnyAction>;
  const isCreatingcreatingBrandApplianceInProgress = useSelector(
    (state: AppStateType) =>
      state.categories.isCreatingcreatingBrandApplianceInProgress
  );
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string[]>();

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  const handleChange = (newValue: string[]) => {
    setValue(newValue);
    dispatch(setBrandAppliancesToAdd(newValue));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
      }}
    >
      <Form form={form}>
        <Form.Item style={{ margin: 0, padding: 0 }} name="brands">
          <Select
            style={{ minWidth: 120 }}
            size="small"
            showSearch
            mode="tags"
            value={value}
            placeholder={props.placeholder}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(data || []).map((d) => ({
              value: d.value,
              label: d.text,
            }))}
          />
        </Form.Item>
      </Form>
      {!isCreatingcreatingBrandApplianceInProgress ? (
        <Link
          style={{ marginLeft: 10 }}
          href=""
          onClick={(e) => {
            e.preventDefault();
            dispatch(createBrandAppliance());
            form.resetFields(["brands"]);
          }}
        >
          Add
        </Link>
      ) : (
        <LoadingOutlined style={{ marginLeft: 10 }} />
      )}
    </div>
  );
};

interface IProps {
  currentSearchParams?: { [param: string]: string };
}

export const SearchBrandInput = ({ currentSearchParams }: IProps) => (
  <SearchBrand
    placeholder="Enter brand"
    currentSearchParams={currentSearchParams}
  />
);
