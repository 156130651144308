import { ILog } from "../../types";
import { LogActionsTypes } from "../actions/logActions";

export const SET_IS_LOGS_LOADING_IN_PROGRESS =
  "log/SET_IS_LOGS_LOADING_IN_PROGRESS";
export const SET_LOGS = "log/SET_LOGS";
export const SET_TOTAL_LOGS = "log/SET_TOTAL_LOGS";

const initialState = {
  isLogsLoadingInProgress: false,
  logs: [] as ILog[],
  totalLogs: 0,
};

type InitialStateType = typeof initialState;

export const logReducer = (
  state = initialState,
  action: LogActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_LOGS_LOADING_IN_PROGRESS:
    case SET_LOGS:
    case SET_TOTAL_LOGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
