import axios from "axios";
import {
  ICompany,
  ICreateCompanyResponse,
  ICreateSiteResponse,
  ICreateTagsResponse,
  IGetAppliancesResponse,
  IGetBrandsResponse,
  IGetCategoriesResponse,
  IGetCitiesResponse,
  IGetCompaniesResponse,
  IGetCompanyResponse,
  IGetCountiesResponse,
  IGetLogsResponse,
  IGetOneTagResponse,
  IGetOneTemplateResponse,
  IGetSiteResponse,
  IGetSitesResponse,
  IGetStatesResponse,
  IGetTagsResponse,
  IGetTemplateTypesResponse,
  IGetTemplatesResponse,
  ITag,
  IUpdateCompany,
  IloginResponse,
} from "./types";
import { mainUrl } from "./helpers/constants";

const instance = axios.create({
  baseURL: mainUrl,
  headers: { "Content-Type": "application/json" },
});

export const authAPI = {
  login: async (email: string, password: string) => {
    const res = await instance.post<IloginResponse>(`api/v1/auth/login`, {
      email,
      password,
    });
    return res.data;
  },
  checkToken: async (token: string) => {
    const res = await instance.post<IloginResponse>(`api/v1/auth/me`, {
      token,
    });
    return res.data;
  },
};

export const siteAPI = {
  loadSites: async (
    sortBy: string,
    sortByField: string,
    page: number,
    serchByName: string,
    serchByState: string,
    serchByCity: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetSitesResponse>(
      `api/v1/site?sortBy=${sortBy}&sortByField=${sortByField}&page=${page}&serchByName=${serchByName}&serchByState=${serchByState}&serchByCity=${serchByCity}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadOneSite: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetSiteResponse>(`api/v1/site/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  newSite: async (
    name: string,
    company: string,
    state: string,
    county: string,
    statusAutoProgressOnCreate: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateSiteResponse>(
      `api/v1/site`,
      {
        name,
        company,
        state,
        county,
        statusAutoProgressOnCreate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateSite: async (
    values: { [param: string]: string },
    // error: boolean,
    // statusAutoProgressOnCreate: boolean,
    siteId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateSiteResponse>(
      `api/v1/site/${siteId}`,
      {
        ...values,
        // error,
        // statusAutoProgressOnCreate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  deleteField: async (fieldName: string, siteId: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateSiteResponse>(
      `api/v1/site/${siteId}`,
      {
        //[fieldName]: "",
        [fieldName]: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};
export const locationAPI = {
  loadStates: async () => {
    const res = await instance.get<IGetStatesResponse>(`api/v1/state`);
    return res.data;
  },
  loadCitiesByState: async (state: string) => {
    const res = await instance.get<IGetCitiesResponse>(`api/v1/city/${state}`);
    return res.data;
  },
  loadCountiesByFips: async (fips: number) => {
    const res = await instance.get<IGetCountiesResponse>(
      `api/v1/county/${fips}`
    );
    return res.data;
  },
  loadCountiesByState: async (state: string) => {
    const res = await instance.get<IGetCountiesResponse>(
      `api/v1/county/bystate/${state}`
    );
    return res.data;
  },
};

export const applianceApi = {
  loadAppliances: async () => {
    const res = await instance.get<IGetAppliancesResponse>(`api/v1/appliance`);
    return res.data;
  },
  loadAppliancesByCategory: async (categoryId: string) => {
    const res = await instance.get<IGetAppliancesResponse>(
      `api/v1/appliance/category/${categoryId}`
    );
    return res.data;
  },
  loadBrands: async () => {
    const res = await instance.get<IGetBrandsResponse>(
      `api/v1/brand-appliance`
    );
    return res.data;
  },
  loadBrandsBySubcategory: async (subCategoryId: string) => {
    const res = await instance.get<IGetBrandsResponse>(
      `api/v1/brand-appliance/subcategory/${subCategoryId}`
    );
    return res.data;
  },
  loadAllBrandAppliances: async () => {
    const res = await instance.get<IGetBrandsResponse>(
      `api/v1/brand-appliance/all`
    );
    return res.data;
  },
};

export const settingsApi = {
  loadCategories: async () => {
    const res = await instance.get<IGetCategoriesResponse>(`api/v1/category`);
    return res.data;
  },
  newBrandAppliance: async (applianceId: string, brandsIds: string[]) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<{
      status: string;
    }>(
      `api/v1/category`,
      { applianceId, brandsIds },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const companyApi = {
  newCompany: async (name: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateCompanyResponse>(
      `api/v1/company`,
      { name },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updCompany: async (company: IUpdateCompany) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateCompanyResponse>(
      `api/v1/company/${company._id}`,
      { ...company, _id: undefined },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updCompanyTrade: async (
    checkedCategories: string[],
    checkedAppliances: string[],
    checkedBrandAppliances: string[],
    companyId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<ICreateCompanyResponse>(
      `api/v1/company/trade/${companyId}`,
      { checkedCategories, checkedAppliances, checkedBrandAppliances },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadCompanies: async () => {
    const res = await instance.get<IGetCompaniesResponse>(`api/v1/company`);
    return res.data;
  },
  loadCompany: async (id: string) => {
    const res = await instance.get<IGetCompanyResponse>(`api/v1/company/${id}`);
    return res.data;
  },
};

export const templatesApi = {
  loadTemplates: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTemplatesResponse>(`api/v1/template`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  loadTemplateTypes: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTemplateTypesResponse>(
      `api/v1/template/types`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadOneTemplate: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetOneTemplateResponse>(
      `api/v1/template/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateTemplate: async (
    template: {
      styles: string;
      html: string;
      script: string;
    },
    templateId: string
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IGetOneTemplateResponse>(
      `api/v1/template/${templateId}`,
      { ...template },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const logsApi = {
  loadLogs: async (page: number) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetLogsResponse>(
      `api/v1/log?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};

export const tagsApi = {
  loadTags: async (page: number) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTagsResponse>(
      `api/v1/tag?page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  loadAllTags: async () => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetTagsResponse>(`api/v1/tag/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  loadOneTag: async (id: string) => {
    const token = localStorage.getItem("token");
    const res = await instance.get<IGetOneTagResponse>(`api/v1/tag/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },
  addTag: async (
    tagName: string,
    fieldInDb: string,
    isGenerateByAi: boolean,
    aiPromt?: string,
    isImage?: boolean
  ) => {
    const token = localStorage.getItem("token");
    const res = await instance.post<ICreateTagsResponse>(
      `api/v1/tag`,
      { tagName, fieldInDb, isGenerateByAi, aiPromt, isImage },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
  updateOneTag: async (id: string, tag: ITag) => {
    const token = localStorage.getItem("token");
    const res = await instance.put<IGetOneTagResponse>(
      `api/v1/tag/${id}`,
      { ...tag },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  },
};
