export const mainUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3755/"
    : "https://sitegen-back.localigniter.com/";

export const siteStatuses = [
  { name: "initial", order: 1 },
  { name: "with_domain", order: 2 },
  { name: "with_content", order: 3 },
  { name: "with_html", order: 4 },
];
