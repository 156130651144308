import { Table, Tag, Typography } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, message, Upload } from "antd";
import { mainUrl } from "../helpers/constants";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import {
  TemplateThunkType,
  getTemplates,
} from "../store/actions/templateActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";
import { getBeautifullDate } from "../helpers/helpers";

export const Templates = () => {
  const { Title, Link } = Typography;
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const dispatch = useDispatch() as Dispatch<TemplateThunkType | AnyAction>;
  const templates = useSelector(
    (state: AppStateType) => state.template.templates
  );

  const props: UploadProps = {
    name: "file",
    action: `${mainUrl}api/v1/template/upload`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    beforeUpload: (file) => {
      const isZIP = file.type === "application/zip";
      if (!isZIP) {
        message.error(`${file.name} is not a zip file`);
      }
      return isZIP || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log("info.file", info.file);
      console.log(info.fileList);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        dispatch(getTemplates());
      } else if (info.file.status === "error") {
        if (info.file?.response?.message) {
          message.error(info.file?.response?.message);
        } else {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    },
  };

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Palette",
        dataIndex: "palette",
        key: "palette",
      },
      {
        title: "Created",
        dataIndex: "created",
        key: "created",
      },
      {
        title: "Updated",
        dataIndex: "updated",
        key: "updated",
      },
    ]);
  }, []);
  useEffect(() => {
    const ds = [] as any[];
    templates.forEach((t) => {
      ds.push({
        key: t._id,
        id: <Link href={`/templates/${t._id}`}>{t._id}</Link>,
        type: <Tag color="magenta">{t.type.toUpperCase()}</Tag>,
        palette:
          t.palette === "dark" ? (
            <Tag color="black">{t.palette.toUpperCase()}</Tag>
          ) : (
            <Tag color="default">{t.palette.toUpperCase()}</Tag>
          ),
        created: getBeautifullDate(t.createdAt),
        updated: getBeautifullDate(t.updatedAt),
      });
    });
    setDataSource(ds);
  }, [templates]);
  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 10,
        flexDirection: "column",
      }}
    >
      <Title level={3}>Upload template chunk</Title>
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>Upload .zip</Button>
      </Upload>
      <Title level={3}>Templates</Title>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};
