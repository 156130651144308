import React, { Dispatch, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { SiteThunkType } from "../store/actions/siteActions";
import { AnyAction } from "redux";
import {
  getCategories,
  setActiveAppliance,
} from "../store/actions/categoriesActions";
import { AppStateType } from "../store";
import { Typography } from "antd";
import {
  getAppliances,
  getApplianceBrands,
  setBrands,
} from "../store/actions/applianceActions";
import { SearchBrandInput } from "../components/categories/SearchBrandInput";

export const Categories = () => {
  const [activeCategory, setActiveCategory] = useState<null | string>(null);

  const { Link, Text } = Typography;
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );

  const activeAppliance = useSelector(
    (state: AppStateType) => state.categories.activeAppliance
  );

  const appliances = useSelector(
    (state: AppStateType) => state.appliance.appliances
  );

  const applianceBrands = useSelector(
    (state: AppStateType) => state.appliance.applianceBrands
  );

  const isGettingBrandsInProgress = useSelector(
    (state: AppStateType) => state.appliance.isGettingBrandsInProgress
  );

  const isGettingAppliancesInProgress = useSelector(
    (state: AppStateType) => state.appliance.isGettingAppliancesInProgress
  );

  const isGettingCategoriesInProgress = useSelector(
    (state: AppStateType) => state.categories.isGettingCategoriesInProgress
  );

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (categories.length > 0 && !activeCategory) {
      setActiveCategory(categories[0]._id);
    }
  }, [categories, activeCategory]);

  useEffect(() => {
    if (activeCategory) {
      dispatch(setBrands([]));
      dispatch(setActiveAppliance(null));
      dispatch(getAppliances(activeCategory));
    }
  }, [activeCategory]);

  useEffect(() => {
    if (activeAppliance) {
      dispatch(getApplianceBrands(activeAppliance));
    }
  }, [activeAppliance]);

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 20,
        flexDirection: "column",
      }}
    >
      {isGettingCategoriesInProgress && (
        <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
      )}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          {categories.map((c) => (
            <Link
              style={{
                fontWeight: activeCategory === c._id ? "bold" : undefined,
                color: activeCategory === c._id ? "red" : undefined,
              }}
              href=""
              onClick={(e) => {
                e.preventDefault();
                setActiveCategory(c._id);
              }}
            >
              {c.name}
            </Link>
          ))}
        </div>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          {isGettingAppliancesInProgress && (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          )}
          {appliances.map((c) => (
            <Link
              style={{
                fontWeight: activeAppliance === c._id ? "bold" : undefined,
                color: activeAppliance === c._id ? "red" : undefined,
              }}
              href=""
              onClick={(e) => {
                e.preventDefault();
                dispatch(setActiveAppliance(c._id));
              }}
            >
              {c.name}
            </Link>
          ))}
        </div>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          {isGettingBrandsInProgress && (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          )}
          {activeAppliance && <SearchBrandInput />}
          {applianceBrands.map((c) => (
            <Text>{c.brand.name}</Text>
          ))}
        </div>
      </div>
    </div>
  );
};
