import { Flex } from "antd";
import type { FormProps } from "antd";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthThunkType,
  checkIsTokenValid,
  loginUser,
} from "../store/actions/authActions";
import { Dispatch, useEffect } from "react";
import { AppStateType } from "../store";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch() as Dispatch<AuthThunkType>;
  useEffect(() => {
    dispatch(checkIsTokenValid());
  });
  type FieldType = {
    email?: string;
    password?: string;
  };
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.email && values.password) {
      dispatch(loginUser(values.email, values.password));
    }
  };

  const isAppInit = useSelector((state: AppStateType) => state.auth.isAppInit);
  const authInProgress = useSelector(
    (state: AppStateType) => state.auth.authInProgress
  );
  const isAuth = useSelector((state: AppStateType) => state.auth.isAuth);

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/sites");
    }
  }, [isAuth]);

  return (
    <Flex
      style={{ width: "100%", height: "100vh" }}
      gap="middle"
      align="center"
      justify="center"
      vertical
    >
      {isAppInit ? (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input type="email" placeholder="Enter email" />
          </Form.Item>

          <Form.Item<FieldType>
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              { min: 5, message: "Password is too short" },
            ]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button disabled={authInProgress} type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <p style={{ fontSize: 16 }}>Loading...</p>
      )}
    </Flex>
  );
};
