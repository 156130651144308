import { ITemplate, ITemplateType } from "../../types";
import { TemplateActionsTypes } from "../actions/templateActions";

export const SET_TEMPLATES = "template/SET_TEMPLATES";
export const SET_IS_TEMPLATES_LOADING_IN_PROGRESS =
  "template/SET_IS_TEMPLATES_LOADING_IN_PROGRESS";
export const SET_IS_ONE_TEMPLATE_LOADING_IN_PROGRESS =
  "template/SET_IS_ONE_TEMPLATE_LOADING_IN_PROGRESS";
export const SET_TEMPLATE = "template/SET_TEMPLATE";
export const SET_TEMPLATE_TYPES = "template/SET_TEMPLATE_TYPES";
export const SET_IS_TEMPLATE_UPDATE_IN_PROGRESS =
  "template/SET_IS_TEMPLATE_UPDATE_IN_PROGRESS";

const initialState = {
  templates: [] as ITemplate[],
  templateTypes: [] as ITemplateType[],
  template: null as null | ITemplate,
  isTemplatesLoadingInProgress: false,
  isTemplateLoadingInProgress: false,
  isTemplateUpdateInProgress: false,
};

type InitialStateType = typeof initialState;

export const templateReducer = (
  state = initialState,
  action: TemplateActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_TEMPLATES:
    case SET_IS_TEMPLATES_LOADING_IN_PROGRESS:
    case SET_IS_ONE_TEMPLATE_LOADING_IN_PROGRESS:
    case SET_TEMPLATE:
    case SET_IS_TEMPLATE_UPDATE_IN_PROGRESS:
    case SET_TEMPLATE_TYPES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
