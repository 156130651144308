import { Button, Form, FormProps, Input, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import {
  CompanyThunkType,
  getCompanies,
} from "../store/actions/companyActions";
import { createCompany } from "../store/actions/companyActions";
import { AppStateType } from "../store";

type FieldType = {
  name: string;
};

export const Companies = () => {
  const dispatch = useDispatch() as Dispatch<CompanyThunkType | AnyAction>;
  const { Title, Link } = Typography;
  const [companyName, setCompnyName] = useState("");
  const [form] = Form.useForm();
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values.name) {
      dispatch(createCompany(values.name));
      form.resetFields(["name"]);
    }
  };

  const isCompanyCreatingInProgress = useSelector(
    (state: AppStateType) => state.company.isCompanyCreatingInProgress
  );
  const companies = useSelector(
    (state: AppStateType) => state.company.companies
  );

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 20,
        flexDirection: "column",
      }}
    >
      <Title level={3}>Add new company</Title>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", padding: 0 }}>
            <Form.Item<FieldType>
              style={{ margin: 0 }}
              label="Company name"
              name="name"
              rules={[{ required: true, message: "Please input company name" }]}
            >
              <Input
                onChange={(e) => setCompnyName(e.target.value)}
                style={{ width: 210 }}
                size="middle"
                placeholder="Enter company name"
              />
            </Form.Item>
          </div>

          <Button
            style={{ marginLeft: 10, marginTop: 30 }}
            htmlType="submit"
            loading={isCompanyCreatingInProgress}
            type="primary"
            size="middle"
            disabled={companyName.length < 2}
          >
            <PlusOutlined /> Create
          </Button>
        </div>
      </Form>
      <Title level={3}>Companies</Title>
      {companies.map((c) => (
        <Link href={`/companies/${c._id}`}>{c.name}</Link>
      ))}
    </div>
  );
};
