import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  IS_COMPANY_CREATING_IN_PROGRESS,
  SET_IS_COMPANIES_LOADING_IN_PROGRESS,
  IS_COMPANY_TRADE_UPDATE_IN_PROGRESS,
  SET_COMPANIES,
  SET_COMPANY,
} from "../reducers/companyReducer";
import { companyApi } from "../../api";
import { notification } from "antd";
import { ICompany, IUpdateCompany } from "../../types";

type SetIsCompanyCreatingInProgress = {
  type: typeof IS_COMPANY_CREATING_IN_PROGRESS;
  payload: { isCompanyCreatingInProgress: boolean };
};

type SetIsCompaniesLoadingInProgress = {
  type: typeof SET_IS_COMPANIES_LOADING_IN_PROGRESS;
  payload: { isCompaniesLoadingInProgress: boolean };
};

type SetIsCompanyTradeUpdateInProgress = {
  type: typeof IS_COMPANY_TRADE_UPDATE_IN_PROGRESS;
  payload: { isCompanyTradeUpdateInProgress: boolean };
};

type SetCompanies = {
  type: typeof SET_COMPANIES;
  payload: { companies: ICompany[] };
};

type SetCompany = {
  type: typeof SET_COMPANY;
  payload: { company: ICompany | null };
};

export type CompanyActionsTypes =
  | SetIsCompanyCreatingInProgress
  | SetIsCompaniesLoadingInProgress
  | SetCompanies
  | SetCompany
  | SetIsCompanyTradeUpdateInProgress;

export const setIsCompanyCreatingInProgress = (
  isCompanyCreatingInProgress: boolean
): SetIsCompanyCreatingInProgress => ({
  type: IS_COMPANY_CREATING_IN_PROGRESS,
  payload: { isCompanyCreatingInProgress },
});

export const setIsCompanyTradeUpdateInProgress = (
  isCompanyTradeUpdateInProgress: boolean
): SetIsCompanyTradeUpdateInProgress => ({
  type: IS_COMPANY_TRADE_UPDATE_IN_PROGRESS,
  payload: { isCompanyTradeUpdateInProgress },
});

export const setIsCompaniesLoadingInProgress = (
  isCompaniesLoadingInProgress: boolean
): SetIsCompaniesLoadingInProgress => ({
  type: SET_IS_COMPANIES_LOADING_IN_PROGRESS,
  payload: { isCompaniesLoadingInProgress },
});

export const setCompanies = (companies: ICompany[]): SetCompanies => ({
  type: SET_COMPANIES,
  payload: { companies },
});

export const setCompany = (company: ICompany | null): SetCompany => ({
  type: SET_COMPANY,
  payload: { company },
});

export type CompanyThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  CompanyActionsTypes
>;

export const createCompany =
  (companyName: string): CompanyThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCompanyCreatingInProgress(true));
      const response = await companyApi.newCompany(companyName);
      if (response.status === "success") {
        dispatch(getCompanies());
        console.log("response", response);
        //dispatch(setAppliances(response.appliances));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error create company",
      });
    } finally {
      dispatch(setIsCompanyCreatingInProgress(false));
    }
  };

export const getCompanies =
  (): CompanyThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsCompaniesLoadingInProgress(true));
      const response = await companyApi.loadCompanies();
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setCompanies(response.companies));
        //dispatch(setAppliances(response.appliances));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error load companies",
      });
    } finally {
      dispatch(setIsCompaniesLoadingInProgress(false));
    }
  };

export const getCompany =
  (companyId: string): CompanyThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCompaniesLoadingInProgress(true));
      const response = await companyApi.loadCompany(companyId);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setCompany(response.company));
        //dispatch(setCompanies(response.companies));
        //dispatch(setAppliances(response.appliances));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error load companies",
      });
    } finally {
      dispatch(setIsCompaniesLoadingInProgress(false));
    }
  };

export const updateCompany =
  (company: IUpdateCompany): CompanyThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCompanyCreatingInProgress(true));
      const response = await companyApi.updCompany(company);
      if (response.status === "success") {
        //console.log("response", response);
        notification.success({
          message: "Success",
          description: "Company base info updated",
        });
        //dispatch(setCompany(response.company));
        //dispatch(setCompanies(response.companies));
        //dispatch(setAppliances(response.appliances));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error update company",
      });
    } finally {
      dispatch(setIsCompanyCreatingInProgress(false));
    }
  };

export const updateCompanyTrade =
  (companyId: string): CompanyThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCompanyTradeUpdateInProgress(true));
      const checkedCategories = getState().categories.checkedCategories;
      const checkedAppliances = getState().categories.checkedAppliances;
      const checkedBrandAppliances =
        getState().categories.checkedBrandAppliances;
      const response = await companyApi.updCompanyTrade(
        checkedCategories,
        checkedAppliances,
        checkedBrandAppliances,
        companyId
      );
      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "Company trade info updated",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error update company trade",
      });
    } finally {
      dispatch(setIsCompanyTradeUpdateInProgress(false));
    }
  };
