import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Router } from "./Router";
import { Provider } from "react-redux";
import store from "./store";

const root = document.getElementById("root");

if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <Provider store={store}>
        <Router />
      </Provider>
    </React.StrictMode>
  );
}
