import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_LOGS_LOADING_IN_PROGRESS,
  SET_LOGS,
  SET_TOTAL_LOGS,
} from "../reducers/logReducer";
import { notification } from "antd";
import { ILog } from "../../types";
import { logsApi } from "../../api";

type SetIsLogsLoadingInProgress = {
  type: typeof SET_IS_LOGS_LOADING_IN_PROGRESS;
  payload: { isLogsLoadingInProgress: boolean };
};

type SetLogs = {
  type: typeof SET_LOGS;
  payload: { logs: ILog[] };
};

type SetTotalLogs = {
  type: typeof SET_TOTAL_LOGS;
  payload: { totalLogs: number };
};

export type LogActionsTypes =
  | SetIsLogsLoadingInProgress
  | SetLogs
  | SetTotalLogs;

export const setIsLogsLoadingInProgress = (
  isLogsLoadingInProgress: boolean
): SetIsLogsLoadingInProgress => ({
  type: SET_IS_LOGS_LOADING_IN_PROGRESS,
  payload: { isLogsLoadingInProgress },
});

export const setTotalLogs = (totalLogs: number): SetTotalLogs => ({
  type: SET_TOTAL_LOGS,
  payload: { totalLogs },
});

export const setLogs = (logs: ILog[]): SetLogs => ({
  type: SET_LOGS,
  payload: { logs },
});

export type LogsThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  LogActionsTypes
>;

export const getLogs =
  (page: number): LogsThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsLogsLoadingInProgress(true));
      const response = await logsApi.loadLogs(page);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setTotalLogs(response?.totalLogs || 0));
        dispatch(setLogs(response?.logs || []));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of logs",
      });
    } finally {
      dispatch(setIsLogsLoadingInProgress(false));
    }
  };
