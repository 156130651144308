import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { AppStateType } from "../store";
import { SitesPreloader } from "../components/sites/SitesPreloader";

import { CreateNewSite } from "../components/sites/CreateNewSite";
import { Dispatch, useEffect, useState } from "react";
import { SiteThunkType, getSites } from "../store/actions/siteActions";
import { AnyAction } from "redux";
import { SitesList } from "../components/sites/SitesList";
import { getStates } from "../store/actions/locationActions";
import { getCategories } from "../store/actions/categoriesActions";
import { IOnSitesUpdate } from "../types";

export const Sites = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;

  const { Title } = Typography;

  const isGettingSitesInProgress = useSelector(
    (state: AppStateType) => state.site.isGettingSitesInProgress
  );

  const sites = useSelector((state: AppStateType) => state.site.sites);
  const sortByField = useSelector(
    (state: AppStateType) => state.site.sortByField
  );
  const sortBy = useSelector((state: AppStateType) => state.site.sortBy);
  const page = useSelector((state: AppStateType) => state.site.page);
  const serchByName = useSelector(
    (state: AppStateType) => state.site.serchByName
  );
  const serchByState = useSelector(
    (state: AppStateType) => state.site.serchByState
  );
  const serchByCity = useSelector(
    (state: AppStateType) => state.site.serchByCity
  );
  const socket = useSelector((state: AppStateType) => state.auth.socket);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    dispatch(getSites());
  }, [sortBy, sortByField, page, serchByName, serchByState, serchByCity]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onSitesUpdate = ({ siteId }: IOnSitesUpdate) => {
    console.log("siteId", siteId);
    if (siteId) {
      dispatch(getSites());
    }
  };

  useEffect(() => {
    socket?.on("sitesUpdate", onSitesUpdate);
  }, [socket]);

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: 20,
          paddingTop: 10,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Title level={3}>Sites</Title>
            {isGettingSitesInProgress && (
              <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
            )}
          </div>
          <Button style={{ width: 180 }} type="primary" onClick={showModal}>
            <PlusOutlined /> Create
          </Button>
        </div>

        {sites.length === 0 && isGettingSitesInProgress && <SitesPreloader />}
        <SitesList />
      </div>
      <Modal
        title="Create new sites"
        open={isModalOpen}
        footer={[]}
        onCancel={() => setIsModalOpen(false)}
      >
        <CreateNewSite />
      </Modal>
    </>
  );
};
