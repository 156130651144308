import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_TEMPLATES,
  SET_IS_TEMPLATES_LOADING_IN_PROGRESS,
  SET_IS_ONE_TEMPLATE_LOADING_IN_PROGRESS,
  SET_TEMPLATE,
  SET_IS_TEMPLATE_UPDATE_IN_PROGRESS,
  SET_TEMPLATE_TYPES,
} from "../reducers/templateReducer";
import { templatesApi } from "../../api";
import { notification } from "antd";
import { ITemplate, ITemplateType } from "../../types";

type SetIsTemplatesLoadingInProgress = {
  type: typeof SET_IS_TEMPLATES_LOADING_IN_PROGRESS;
  payload: { isTemplatesLoadingInProgress: boolean };
};

type SetIsTemplateLoadingInProgress = {
  type: typeof SET_IS_ONE_TEMPLATE_LOADING_IN_PROGRESS;
  payload: { isTemplateLoadingInProgress: boolean };
};

type SetIsTemplateUpdateInProgress = {
  type: typeof SET_IS_TEMPLATE_UPDATE_IN_PROGRESS;
  payload: { isTemplateUpdateInProgress: boolean };
};

type SetTemplates = {
  type: typeof SET_TEMPLATES;
  payload: { templates: ITemplate[] };
};

type SetTemplate = {
  type: typeof SET_TEMPLATE;
  payload: { template: null | ITemplate };
};

type SetTemplateTypes = {
  type: typeof SET_TEMPLATE_TYPES;
  payload: { templateTypes: ITemplateType[] };
};

export type TemplateActionsTypes =
  | SetIsTemplatesLoadingInProgress
  | SetIsTemplateLoadingInProgress
  | SetTemplates
  | SetTemplate
  | SetIsTemplateUpdateInProgress
  | SetTemplateTypes;

export const setIsTemplatesLoadingInProgress = (
  isTemplatesLoadingInProgress: boolean
): SetIsTemplatesLoadingInProgress => ({
  type: SET_IS_TEMPLATES_LOADING_IN_PROGRESS,
  payload: { isTemplatesLoadingInProgress },
});

export const setIsTemplateLoadingInProgress = (
  isTemplateLoadingInProgress: boolean
): SetIsTemplateLoadingInProgress => ({
  type: SET_IS_ONE_TEMPLATE_LOADING_IN_PROGRESS,
  payload: { isTemplateLoadingInProgress },
});

export const setIsTemplateUpdateInProgress = (
  isTemplateUpdateInProgress: boolean
): SetIsTemplateUpdateInProgress => ({
  type: SET_IS_TEMPLATE_UPDATE_IN_PROGRESS,
  payload: { isTemplateUpdateInProgress },
});

export const setTemplates = (templates: ITemplate[]): SetTemplates => ({
  type: SET_TEMPLATES,
  payload: { templates },
});

export const setTemplate = (template: null | ITemplate): SetTemplate => ({
  type: SET_TEMPLATE,
  payload: { template },
});

export const setTemplateTypes = (
  templateTypes: ITemplateType[]
): SetTemplateTypes => ({
  type: SET_TEMPLATE_TYPES,
  payload: { templateTypes },
});

export type TemplateThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  TemplateActionsTypes
>;

export const getTemplates =
  (): TemplateThunkType => async (dispatch, getState) => {
    try {
      dispatch(setIsTemplatesLoadingInProgress(true));
      const response = await templatesApi.loadTemplates();
      if (response.status === "success") {
        dispatch(setTemplates(response.templates));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of templates",
      });
    } finally {
      dispatch(setIsTemplatesLoadingInProgress(false));
    }
  };

export const getOneTemplate =
  (templateId: string): TemplateThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsTemplateLoadingInProgress(true));
      const response = await templatesApi.loadOneTemplate(templateId);
      if (response.status === "success") {
        dispatch(setTemplate(response.template));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading template",
      });
    } finally {
      dispatch(setIsTemplateLoadingInProgress(false));
    }
  };

export const updateTemplate =
  (
    template: {
      styles: string;
      html: string;
      script: string;
    },
    templateId: string
  ): TemplateThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsTemplateUpdateInProgress(true));
      const response = await templatesApi.updateTemplate(template, templateId);
      if (response.status === "success") {
        dispatch(getOneTemplate(templateId));
        notification.success({
          message: "Success",
          description: "Template updated successfully",
        });
        dispatch(setTemplate(response.template));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error updating template",
      });
    } finally {
      dispatch(setIsTemplateUpdateInProgress(false));
    }
  };

export const getTemplateTypes =
  (): TemplateThunkType => async (dispatch, getState) => {
    try {
      const response = await templatesApi.loadTemplateTypes();
      if (response.status === "success") {
        dispatch(setTemplateTypes(response.templateTypes));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of templateTypes",
      });
    } finally {
    }
  };
