import { ITag } from "../../types";
import { TagActionsTypes } from "../actions/tagActions";

export const SET_IS_TAGS_LOADING_IN_PROGRESS =
  "tag/SET_IS_TAGS_LOADING_IN_PROGRESS";
export const SET_TAGS = "tag/SET_TAGS";
export const SET_TAG = "tag/SET_TAG";
export const SET_TOTAL_TAGS = "tag/SET_TOTAL_TAGS";
export const SET_IS_TAG_ADDING_IN_PROGRESS =
  "tag/SET_IS_TAG_ADDING_IN_PROGRESS";
export const SET_IS_TAG_UPDATE_IN_PROGRESS =
  "tag/SET_IS_TAG_UPDATE_IN_PROGRESS";
export const SET_ALL_TAGS = "tag/SET_ALL_TAGS";

const initialState = {
  isTagsLoadingInProgress: false,
  isTagAddingInProgress: false,
  tags: [] as ITag[],
  allTags: [] as ITag[],
  tag: null as null | ITag,
  totalTags: 0,
  isTagUpdateInProgress: false,
};

type InitialStateType = typeof initialState;

export const tagReducer = (
  state = initialState,
  action: TagActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_TAGS_LOADING_IN_PROGRESS:
    case SET_IS_TAG_ADDING_IN_PROGRESS:
    case SET_TAGS:
    case SET_TAG:
    case SET_TOTAL_TAGS:
    case SET_IS_TAG_UPDATE_IN_PROGRESS:
    case SET_ALL_TAGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
