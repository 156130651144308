import React, { Dispatch } from "react";
import { ISite } from "../../types";
import { siteStatuses } from "../../helpers/constants";
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { SiteThunkType, updateSite } from "../../store/actions/siteActions";
import { AnyAction } from "redux";

interface IProps {
  id: string;
  site: ISite;
}

export const StatusBar = ({ id, site }: IProps) => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const isStatusFilled = (status: { name: string; order: number }) => {
    const siteStatus = siteStatuses.find((s) => s.name === site.status);
    if (siteStatus && status.order <= siteStatus?.order) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {siteStatuses.map((s, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: 120,
            marginLeft: index === 0 ? -40 : undefined,
          }}
          key={s.name}
        >
          {index > 0 && <ArrowRightOutlined style={{ marginTop: 10 }} />}
          <Popconfirm
            title="Change site status"
            description={`Set status to ${s.name}?`}
            onConfirm={() =>
              id ? dispatch(updateSite({ status: s.name }, id)) : null
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: 40,
                  height: 40,
                  border: `1px solid ${isStatusFilled(s) ? "#52c41a" : "grey"}`,
                  borderRadius: 20,
                  backgroundColor: isStatusFilled(s) ? "#52c41a" : "grey",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isStatusFilled(s) ? (
                  <CheckOutlined style={{ color: "white" }} />
                ) : (
                  <CloseOutlined style={{ color: "white" }} />
                )}
              </div>
              <div style={{ marginTop: 10 }}>{s.name}</div>
            </div>
          </Popconfirm>
        </div>
      ))}
    </div>
  );
};
