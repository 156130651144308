import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStateType } from "./store";

interface IProps {
  element: React.JSX.Element;
}

export const RequireAuth = ({ element }: IProps) => {
  let navigate = useNavigate();
  const isAuth = useSelector((state: AppStateType) => state.auth.isAuth);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, [isAuth]);

  if (isAuth) {
    return element;
  } else {
    return null;
  }
};
