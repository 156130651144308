import {
  Breadcrumb,
  Button,
  Form,
  FormProps,
  Input,
  Tag,
  Typography,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { Dispatch, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  TemplateThunkType,
  getOneTemplate,
  updateTemplate,
} from "../store/actions/templateActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../store";

type FieldType = {
  html: string;
  styles: string;
  script: string;
};

export const Template = () => {
  const [form] = Form.useForm();

  const { TextArea } = Input;
  const dispatch = useDispatch() as Dispatch<TemplateThunkType | AnyAction>;
  let { id } = useParams();
  const template = useSelector(
    (state: AppStateType) => state.template.template
  );
  const isTemplateUpdateInProgress = useSelector(
    (state: AppStateType) => state.template.isTemplateUpdateInProgress
  );
  useEffect(() => {
    if (id) {
      dispatch(getOneTemplate(id));
    }
  }, [id]);

  useEffect(() => {
    console.log("template", template);
    if (template?._id) {
      form.setFieldValue("html", template.html);
      form.setFieldValue("styles", template.styles);
      form.setFieldValue("script", template.script);
    }
  }, [template?._id]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (template?._id) {
      dispatch(updateTemplate(values, template?._id));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 10,
        flexDirection: "column",
      }}
    >
      <Breadcrumb
        items={[
          {
            href: "/templates",
            title: (
              <>
                <ArrowLeftOutlined />
                <span>Templates</span>
              </>
            ),
          },
          {
            title: `Template ${id}`,
          },
        ]}
      />
      <div style={{ marginTop: 20 }}>
        <Tag color="magenta">{template?.type.toUpperCase()}</Tag>
        {template?.palette === "dark" ? (
          <Tag color="black">{template?.palette.toUpperCase()}</Tag>
        ) : (
          <Tag color="default">{template?.palette.toUpperCase()}</Tag>
        )}
      </div>

      <Form
        style={{ marginTop: 20 }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item<FieldType> label="HTML" name="html">
          <TextArea rows={5} value={template?.html} />
        </Form.Item>
        <Form.Item<FieldType> label="Styles" name="styles">
          <TextArea rows={5} value={template?.styles} />
        </Form.Item>
        <Form.Item<FieldType> label="Script" name="script">
          <TextArea rows={5} value={template?.script} />
        </Form.Item>
        <Button
          style={{ width: 150 }}
          htmlType="submit"
          loading={isTemplateUpdateInProgress}
          type="primary"
          size="middle"
          disabled={false}
        >
          Save
        </Button>
      </Form>
    </div>
  );
};
