import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SiteThunkType } from "../../store/actions/siteActions";
import { AnyAction } from "redux";
import {
  addCheckedAppliance,
  addCheckedBrandAppliance,
  addCheckedBrandApplianceGroup,
  addCheckedCategory,
  getCategories,
  removeCheckedAppliance,
  removeCheckedBrandAppliance,
  removeCheckedBrandApplianceGroup,
  removeCheckedCategory,
} from "../../store/actions/categoriesActions";
import { AppStateType } from "../../store";
import { useParams } from "react-router-dom";
import { Checkbox, CheckboxProps, Typography } from "antd";
import {
  getAllApplianceBrands,
  getAllAppliances,
} from "../../store/actions/applianceActions";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export const CompanyTrade = () => {
  const { Link } = Typography;
  let { id } = useParams();
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const categories = useSelector(
    (state: AppStateType) => state.categories.categories
  );
  const appliances = useSelector(
    (state: AppStateType) => state.appliance.appliances
  );
  const applianceBrands = useSelector(
    (state: AppStateType) => state.appliance.applianceBrands
  );
  const checkedCategories = useSelector(
    (state: AppStateType) => state.categories.checkedCategories
  );
  const checkedAppliances = useSelector(
    (state: AppStateType) => state.categories.checkedAppliances
  );
  const checkedBrandAppliances = useSelector(
    (state: AppStateType) => state.categories.checkedBrandAppliances
  );
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllAppliances());
    dispatch(getAllApplianceBrands());
  }, []);

  const uncheckAll = (applianceId: string) => {
    let idsArr: string[] = [];
    const appBrandByAppliance = [
      ...applianceBrands?.filter((ab) => ab.appliance === applianceId),
    ];
    appBrandByAppliance.forEach((ab) => {
      idsArr.push(ab._id);
    });
    // console.log("idsArr", idsArr);
    dispatch(removeCheckedBrandApplianceGroup(idsArr));
    dispatch(removeCheckedAppliance(applianceId));
  };

  const checkAll = (applianceId: string) => {
    let idsArr: string[] = [];
    const appBrandByAppliance = [
      ...applianceBrands?.filter((ab) => ab.appliance === applianceId),
    ];
    appBrandByAppliance.forEach((ab) => {
      idsArr.push(ab._id);
    });
    dispatch(addCheckedBrandApplianceGroup(idsArr));
  };

  const onChangeCategory: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      dispatch(addCheckedCategory(e.target.value));
    } else {
      dispatch(removeCheckedCategory(e.target.value));
    }
  };
  const onChangeAppliance: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      dispatch(addCheckedAppliance(e.target.value));
      const categoryId = appliances.find(
        (a) => a._id === e.target.value
      )?.category;
      if (categoryId) {
        dispatch(addCheckedCategory(categoryId));
      }
    } else {
      dispatch(removeCheckedAppliance(e.target.value));
    }
  };
  const onChangeBrandAppliance: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      dispatch(addCheckedBrandAppliance(e.target.value));
      const applianceId = applianceBrands.find(
        (ab) => ab._id === e.target.value
      )?.appliance;
      if (applianceId) {
        dispatch(addCheckedAppliance(applianceId));
      }
      const categoryId = appliances.find(
        (a) => a._id === applianceId
      )?.category;
      if (categoryId) {
        dispatch(addCheckedCategory(categoryId));
      }
    } else {
      dispatch(removeCheckedBrandAppliance(e.target.value));
    }
  };

  const checkIsShowCheckAll = (id: string) => {
    const totalCount = [...applianceBrands?.filter((ab) => ab.appliance === id)]
      .length;
    let checkedCount = 0;
    [...applianceBrands?.filter((ab) => ab.appliance === id)].forEach((ab) => {
      if (checkedBrandAppliances.includes(ab._id)) {
        checkedCount += 1;
      }
    });
    return (
      checkedAppliances.includes(id) &&
      checkedCount !== totalCount &&
      totalCount > 0
    );
  };

  const checkIsShowUncheckAll = (id: string) => {
    let indicator = false;
    [...applianceBrands?.filter((ab) => ab.appliance === id)].forEach((ab) => {
      if (checkedBrandAppliances.includes(ab._id)) {
        indicator = true;
      }
    });
    return indicator;
  };

  return (
    <div>
      {categories.map((c) => (
        <div>
          <Checkbox
            checked={checkedCategories.some((cc) => cc === c._id)}
            value={c._id}
            onChange={onChangeCategory}
          >
            {c.name}
          </Checkbox>
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 25 }}
          >
            {[...appliances.filter((a) => a.category === c._id)].map((ap) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: 400,
                    justifyContent: "space-between",
                  }}
                >
                  <Checkbox
                    checked={checkedAppliances.some((ca) => ca === ap._id)}
                    onChange={onChangeAppliance}
                    value={ap._id}
                  >
                    {ap.name}
                  </Checkbox>
                  <div>
                    {checkIsShowCheckAll(ap._id) && (
                      <Link
                        style={{ fontSize: 12 }}
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          checkAll(ap._id);
                        }}
                      >
                        <CheckOutlined />
                        Check All
                      </Link>
                    )}
                    {checkIsShowUncheckAll(ap._id) && (
                      <Link
                        style={{ marginLeft: 10, fontSize: 12, color: "red" }}
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          uncheckAll(ap._id);
                        }}
                      >
                        <CloseOutlined />
                        Uncheck All
                      </Link>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 25,
                  }}
                >
                  {[
                    ...applianceBrands?.filter((ab) => ab.appliance === ap._id),
                  ].map((abr) => (
                    <Checkbox
                      checked={checkedBrandAppliances.some(
                        (cba) => cba === abr._id
                      )}
                      value={abr._id}
                      onChange={onChangeBrandAppliance}
                    >
                      {abr?.brand?.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
