import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_AUTH_ERROR_MESSAGE,
  SET_AUTH_IN_PROGRESS,
  SET_IS_AUTH,
  SET_USER_DATA,
  SET_IS_APP_INIT,
  SET_SOCKET,
} from "../reducers/authReducer";
import { authAPI } from "../../api";
import { IUser } from "../../types";
import { Socket } from "socket.io-client";
import { notification } from "antd";

type SetAuthErrorMessageActionType = {
  type: typeof SET_AUTH_ERROR_MESSAGE;
  payload: { authErrorMessage: null | string };
};

type SetAuthInProgressActionType = {
  type: typeof SET_AUTH_IN_PROGRESS;
  payload: { authInProgress: boolean };
};

type SetUser = {
  type: typeof SET_USER_DATA;
  payload: { user: null | IUser };
};

type SetIsAuthActionType = {
  type: typeof SET_IS_AUTH;
  payload: { isAuth: boolean };
};

type SetIsAppInit = {
  type: typeof SET_IS_APP_INIT;
  payload: { isAppInit: boolean };
};

export type SetSocket = {
  type: typeof SET_SOCKET;
  payload: { socket: null | Socket };
};

export type AuthActionsTypes =
  | SetIsAuthActionType
  | SetAuthInProgressActionType
  | SetAuthErrorMessageActionType
  | SetUser
  | SetIsAppInit
  | SetSocket;

export const setIsAuth = (isAuth: boolean): SetIsAuthActionType => ({
  type: SET_IS_AUTH,
  payload: { isAuth },
});

export const setAuthErrorMessage = (
  authErrorMessage: string | null
): SetAuthErrorMessageActionType => ({
  type: SET_AUTH_ERROR_MESSAGE,
  payload: { authErrorMessage },
});

const setAuthInProgress = (
  authInProgress: boolean
): SetAuthInProgressActionType => ({
  type: SET_AUTH_IN_PROGRESS,
  payload: { authInProgress },
});

const setIsAppInit = (isAppInit: boolean): SetIsAppInit => ({
  type: SET_IS_APP_INIT,
  payload: { isAppInit },
});

export const setUser = (user: IUser | null): SetUser => ({
  type: SET_USER_DATA,
  payload: { user },
});

export const setSocket = (socket: null | Socket): SetSocket => ({
  type: SET_SOCKET,
  payload: { socket },
});

export type AuthThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  AuthActionsTypes
>;

export const loginUser =
  (email: string, password: string): AuthThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setAuthInProgress(true));
      const response = await authAPI.login(email, password);
      console.log("response", response);
      if (response.status === "success" && response.token) {
        localStorage.setItem("token", response.token);
        dispatch(setIsAuth(true));
        dispatch(setUser(response.user));
      }
    } catch (e: any) {
      console.log(e);
      if (e?.response?.data?.text) {
        notification.error({
          message: "Error",
          description: e?.response?.data?.text,
        });
      } else {
        notification.error({
          message: "Error",
          description: "Unknown error",
        });
      }
    } finally {
      dispatch(setAuthInProgress(false));
    }
  };

export const checkIsTokenValid =
  (): AuthThunkType => async (dispatch, getState) => {
    try {
      const jwt = localStorage.getItem("token");
      if (jwt) {
        let response = await authAPI.checkToken(jwt);
        console.log("response", response);
        if (response.status === "success") {
          dispatch(setIsAuth(true));
          dispatch(setUser(response.user));
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setIsAppInit(true));
    }
  };

export const logoutUser = (): AuthThunkType => async (dispatch) => {
  localStorage.setItem("token", "");
  dispatch(setIsAuth(false));
  dispatch(setUser(null));
};
