export const getBeautifullDate = (date: string) => {
  return `${new Date(Date.parse(date)).toLocaleDateString("en-US")} ${
    new Date(Date.parse(date)).toLocaleTimeString("en-US")?.split(":")[0]
  }:${
    new Date(Date.parse(date))
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      ?.split(":")[1]
  }`;
};
