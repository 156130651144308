import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_TAGS_LOADING_IN_PROGRESS,
  SET_TAGS,
  SET_TOTAL_TAGS,
  SET_IS_TAG_ADDING_IN_PROGRESS,
  SET_TAG,
  SET_IS_TAG_UPDATE_IN_PROGRESS,
  SET_ALL_TAGS,
} from "../reducers/tagReducer";
import { FormInstance, notification } from "antd";
import { ITag } from "../../types";
import { tagsApi } from "../../api";

type SetIsTagsLoadingInProgress = {
  type: typeof SET_IS_TAGS_LOADING_IN_PROGRESS;
  payload: { isTagsLoadingInProgress: boolean };
};

type SetTags = {
  type: typeof SET_TAGS;
  payload: { tags: ITag[] };
};

type SetTag = {
  type: typeof SET_TAG;
  payload: { tag: ITag };
};

type SetTotalTags = {
  type: typeof SET_TOTAL_TAGS;
  payload: { totalTags: number };
};

type SetIsTagAddingInProgress = {
  type: typeof SET_IS_TAG_ADDING_IN_PROGRESS;
  payload: { isTagAddingInProgress: boolean };
};

type SetIsTagUpdateInProgress = {
  type: typeof SET_IS_TAG_UPDATE_IN_PROGRESS;
  payload: { isTagUpdateInProgress: boolean };
};

type SetAllTags = {
  type: typeof SET_ALL_TAGS;
  payload: { allTags: ITag[] };
};

export type TagActionsTypes =
  | SetIsTagsLoadingInProgress
  | SetTags
  | SetTotalTags
  | SetIsTagAddingInProgress
  | SetTag
  | SetIsTagUpdateInProgress
  | SetAllTags;

export const setIsTagsLoadingInProgress = (
  isTagsLoadingInProgress: boolean
): SetIsTagsLoadingInProgress => ({
  type: SET_IS_TAGS_LOADING_IN_PROGRESS,
  payload: { isTagsLoadingInProgress },
});

export const setIsTagAddingInProgress = (
  isTagAddingInProgress: boolean
): SetIsTagAddingInProgress => ({
  type: SET_IS_TAG_ADDING_IN_PROGRESS,
  payload: { isTagAddingInProgress },
});

export const setIsTagUpdateInProgress = (
  isTagUpdateInProgress: boolean
): SetIsTagUpdateInProgress => ({
  type: SET_IS_TAG_UPDATE_IN_PROGRESS,
  payload: { isTagUpdateInProgress },
});

export const setTotalTags = (totalTags: number): SetTotalTags => ({
  type: SET_TOTAL_TAGS,
  payload: { totalTags },
});

export const setTags = (tags: ITag[]): SetTags => ({
  type: SET_TAGS,
  payload: { tags },
});

export const setTag = (tag: ITag): SetTag => ({
  type: SET_TAG,
  payload: { tag },
});

export const setAllTags = (allTags: ITag[]): SetAllTags => ({
  type: SET_ALL_TAGS,
  payload: { allTags },
});

export type TagThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  TagActionsTypes
>;

export const getTags =
  (page: number): TagThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsTagsLoadingInProgress(true));
      const response = await tagsApi.loadTags(page);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setTotalTags(response?.totalTags || 0));
        dispatch(setTags(response?.tags || []));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading list of tags",
      });
    } finally {
      dispatch(setIsTagsLoadingInProgress(false));
    }
  };

export const getAllTags = (): TagThunkType => async (dispatch, getState) => {
  try {
    dispatch(setIsTagsLoadingInProgress(true));
    const response = await tagsApi.loadAllTags();
    if (response.status === "success") {
      dispatch(setAllTags(response?.tags || []));
    }
  } catch (e) {
    notification.error({
      message: "Error",
      description: "Error loading list of all tags",
    });
  } finally {
    dispatch(setIsTagsLoadingInProgress(false));
  }
};

export const getOneTag =
  (id: string): TagThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsTagsLoadingInProgress(true));
      const response = await tagsApi.loadOneTag(id);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setTag(response?.tag || null));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading tag info",
      });
    } finally {
      dispatch(setIsTagsLoadingInProgress(false));
    }
  };

export const updateTag =
  (id: string, tag: ITag): TagThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsTagUpdateInProgress(true));
      const response = await tagsApi.updateOneTag(id, tag);
      if (response.status === "success") {
        console.log("response", response);
        dispatch(setTag(response?.tag || null));
        notification.success({
          message: "Success",
          description: "Tag updated",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading tag info",
      });
    } finally {
      dispatch(setIsTagUpdateInProgress(false));
    }
  };

export const addTag =
  (
    tagName: string,
    fieldInDb: string,
    isGenerateByAi: boolean,
    aiPromt: string | undefined,
    isImage: boolean | undefined,
    form: FormInstance<any> | undefined
  ): TagThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsTagAddingInProgress(true));
      const response = await tagsApi.addTag(
        tagName,
        fieldInDb,
        isGenerateByAi,
        aiPromt,
        isImage
      );
      if (response.status === "success") {
        console.log("response", response);
        dispatch(getTags(1));
        notification.success({
          message: "Success",
          description: "Tag added",
        });
        form?.resetFields();
      } else if (response.status === "already_exist") {
        notification.error({
          message: "Error",
          description: "Tag already exist",
        });
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error adding tag",
      });
    } finally {
      dispatch(setIsTagAddingInProgress(false));
    }
  };
