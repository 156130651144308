import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppStateType } from "../store";

export const Main = () => {
  let navigate = useNavigate();
  const isAuth = useSelector((state: AppStateType) => state.auth.isAuth);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    } else {
      navigate("/sites");
    }
  }, [isAuth]);

  return null;
};
