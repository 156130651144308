import {
  Breadcrumb,
  Button,
  Form,
  FormProps,
  Input,
  Popconfirm,
  Select,
  Switch,
  Tag,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import React, { Dispatch, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SiteThunkType,
  deleteField,
  getOneSite,
  updateSite,
} from "../store/actions/siteActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";
import { IOnSitesUpdate } from "../types";
import { getTags } from "../store/actions/tagActions";
import { mainUrl } from "../helpers/constants";
import { StatusBar } from "../components/sites/StatusBar";
import { getTemplateTypes } from "../store/actions/templateActions";

export const Site = () => {
  const dispatch = useDispatch() as Dispatch<SiteThunkType | AnyAction>;
  const { Link, Title } = Typography;
  const [form] = Form.useForm();
  let { id } = useParams();

  const site = useSelector((state: AppStateType) => state.site.site);
  const socket = useSelector((state: AppStateType) => state.auth.socket);
  const isGettingSitesInProgress = useSelector(
    (state: AppStateType) => state.site.isGettingSitesInProgress
  );
  const isUpdatingSiteInProgress = useSelector(
    (state: AppStateType) => state.site.isUpdatingSiteInProgress
  );
  const tags = useSelector((state: AppStateType) => state.tag.tags);

  const templateTypes = useSelector(
    (state: AppStateType) => state.template.templateTypes
  );

  type FieldType = {
    [param: string]: string;
  };

  useEffect(() => {
    if (id) {
      dispatch(getOneSite(id));
      dispatch(getTemplateTypes());
      dispatch(getTags(1));
    }
  }, [id]);

  useEffect(() => {
    console.log("templateTypes", templateTypes);
  }, [templateTypes]);

  useEffect(() => {
    if (site) {
      form.setFieldValue("name", site.name);
      form.setFieldValue("status", site.status);
      tags.forEach((t) => {
        if (t.fieldInDb && site?.[t.fieldInDb]) {
          form.setFieldValue(t.fieldInDb, site?.[t.fieldInDb]);
        }
      });
    }
  }, [site, tags]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (values && id && values.name) {
      dispatch(updateSite(values, id));
    }
  };

  const onSitesUpdate = ({ siteId }: IOnSitesUpdate) => {
    console.log("siteId", siteId);
    if (siteId) {
      dispatch(getOneSite(siteId));
    }
  };

  useEffect(() => {
    socket?.on("sitesUpdate", onSitesUpdate);
  }, [socket]);

  const { TextArea } = Input;

  const cronOnChange = (checked: boolean) => {
    if (id) {
      dispatch(updateSite({ statusAutoProgressOnCreate: `${checked}` }, id));
    }
  };

  const errorOnChange = (checked: boolean) => {
    if (id) {
      dispatch(updateSite({ error: `${checked}` }, id));
    }
  };

  const advanageIconsIndexes = [1, 2, 3, 4] as 1[] | 2[] | 3[] | 4[];
  return (
    <>
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Breadcrumb
            style={{ marginTop: 10, marginBottom: 20 }}
            items={[
              {
                href: "/sites",
                title: (
                  <>
                    <ArrowLeftOutlined />
                    <span>Sites</span>
                  </>
                ),
              },
              {
                title: "Site",
              },
            ]}
          />
          {isGettingSitesInProgress && <LoadingOutlined />}
        </div>
        <Title style={{ marginTop: 0 }} level={4}>
          Status flow
        </Title>
        {id && site && <StatusBar id={id} site={site} />}
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ padding: 20 }}>
          <Form
            style={{ minWidth: 400 }}
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item<FieldType>
              label={
                <Title style={{ marginTop: 0 }} level={4}>
                  Site name
                </Title>
              }
              name="name"
              rules={[{ required: true, message: "Site Name can't be empty" }]}
            >
              <Input size="middle" placeholder="Enter site name" />
            </Form.Item>
            {tags.map((t) =>
              t.fieldInDb && site?.[t.fieldInDb] ? (
                <Form.Item<FieldType>
                  label={
                    <Title style={{ marginTop: 0 }} level={4}>
                      {t.fieldInDb}
                    </Title>
                  }
                  name={t.fieldInDb}
                >
                  <TextArea
                    rows={2}
                    size="middle"
                    placeholder={`Enter ${t.fieldInDb}`}
                  />
                </Form.Item>
              ) : null
            )}

            <Button
              style={{ width: 150 }}
              htmlType="submit"
              loading={isUpdatingSiteInProgress}
              type="primary"
              size="middle"
              disabled={false}
            >
              Save
            </Button>
          </Form>
        </div>
        <div style={{ borderLeft: "1px solid", paddingLeft: 20 }}>
          <div style={{ paddingTop: 20, paddingBottom: 10 }}>
            <Title style={{ marginTop: 0 }} level={4}>
              Visible for CRON?
            </Title>

            <Switch
              loading={isUpdatingSiteInProgress}
              checked={site?.statusAutoProgressOnCreate}
              onChange={cronOnChange}
            />
          </div>

          <div style={{ paddingBottom: 10 }}>
            <Title style={{ marginTop: 20 }} level={4}>
              Is error?
            </Title>

            <Switch
              disabled={!site?.error}
              loading={isUpdatingSiteInProgress}
              defaultChecked={site?.error}
              checked={site?.error}
              onChange={errorOnChange}
            />
          </div>

          {site?.companyLogo && (
            <div
              style={{
                paddingBottom: 10,
                flexDirection: "row",
                width: "100%",
                display: "flex",
              }}
            >
              <div>
                <Title style={{ marginTop: 20 }} level={4}>
                  Company logo
                </Title>

                <img
                  width={200}
                  alt="company logo"
                  src={`${mainUrl}${site?.companyLogo?.replace(
                    "/data/uploads/",
                    ""
                  )}`}
                />
              </div>
              <div
                style={{
                  paddingTop: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete?"
                  onConfirm={() =>
                    id ? dispatch(deleteField("companyLogo", id)) : null
                  }
                >
                  <Button
                    style={{ width: 150, color: "red" }}
                    loading={isUpdatingSiteInProgress}
                    type="link"
                    size="middle"
                  >
                    Delete logo
                  </Button>
                </Popconfirm>
              </div>
            </div>
          )}

          {advanageIconsIndexes.map((i) => {
            if (site?.[`advantageIcon${i}`]) {
              return (
                <div
                  style={{
                    paddingBottom: 10,
                    flexDirection: "row",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div>
                    <Title style={{ marginTop: 20 }} level={4}>
                      Advantage icon {i}
                    </Title>

                    <img
                      width={200}
                      alt="company logo"
                      src={`${mainUrl}${site?.[`advantageIcon${i}`]?.replace(
                        "/data/uploads/",
                        ""
                      )}`}
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: 50,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete?"
                      onConfirm={() =>
                        id
                          ? dispatch(deleteField(`advantageIcon${i}`, id))
                          : null
                      }
                    >
                      <Button
                        style={{ width: 150, color: "red" }}
                        loading={isUpdatingSiteInProgress}
                        type="link"
                        size="middle"
                      >
                        Delete icon
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}

          {templateTypes.some((tt) => !!site?.[`${tt.type}Template`]) && (
            <div>
              <Title style={{ marginTop: 20 }} level={4}>
                Templates
              </Title>
              {templateTypes.map((tt) => {
                if (!!site?.[`${tt.type}Template`]) {
                  return (
                    <div style={{ marginTop: 10 }}>
                      <Tag color="magenta">{tt.type.toUpperCase()}</Tag>
                      <Link
                        target="_blank"
                        href={`/templates/${site?.[`${tt.type}Template`]}`}
                      >
                        {site?.[`${tt.type}Template`]}
                      </Link>
                      <Popconfirm
                        title="Delete"
                        description="Are you sure to delete?"
                        onConfirm={() =>
                          id
                            ? dispatch(deleteField(`${tt.type}Template`, id))
                            : null
                        }
                      >
                        <Button
                          style={{ width: 150, color: "red" }}
                          loading={isUpdatingSiteInProgress}
                          type="link"
                          size="middle"
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
          {site?.htmlBuildLocation && (
            <>
              <Title style={{ marginTop: 20 }} level={4}>
                Build
              </Title>
              <div
                style={{
                  paddingBottom: 20,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <Link
                  target="_blank"
                  href={`${mainUrl}${site?.htmlBuildLocation?.replace(
                    "/data/uploads/",
                    ""
                  )}`}
                >
                  <LinkOutlined /> HTML BUILD
                </Link>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Popconfirm
                    title="Delete"
                    description="Are you sure to delete?"
                    onConfirm={() =>
                      id ? dispatch(deleteField("htmlBuildLocation", id)) : null
                    }
                  >
                    <Button
                      style={{ width: 150, color: "red" }}
                      loading={isUpdatingSiteInProgress}
                      type="link"
                      size="middle"
                    >
                      Delete build
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
