import { IAppliance, IBrandAppliance } from "../../types";
import { ApplianceActionsTypes } from "../actions/applianceActions";

export const SET_IS_GETTING_APPLIANCES_IN_PROGRESS =
  "appliance/SET_IS_GETTING_APPLIANCES_IN_PROGRESS";
export const SET_IS_GETTING_BRANDS_IN_PROGRESS =
  "appliance/SET_IS_GETTING_BRANDS_IN_PROGRESS";
export const SET_APPLIANCES = "appliance/SET_APPLIANCES";
export const SET_BRANDS = "appliance/SET_BRANDS";

const initialState = {
  isGettingAppliancesInProgress: false,
  isGettingBrandsInProgress: false,
  appliances: [] as IAppliance[],
  applianceBrands: [] as IBrandAppliance[],
};

type InitialStateType = typeof initialState;

export const applianceReducer = (
  state = initialState,
  action: ApplianceActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_GETTING_APPLIANCES_IN_PROGRESS:
    case SET_IS_GETTING_BRANDS_IN_PROGRESS:
    case SET_APPLIANCES:
    case SET_BRANDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
