import { Pagination, Typography } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getLogs, LogsThunkType } from "../store/actions/logActions";
import { AnyAction } from "redux";
import { AppStateType } from "../store";

export const Logs = () => {
  const { Text } = Typography;
  const dispatch = useDispatch() as Dispatch<LogsThunkType | AnyAction>;
  const [currentSearchParams, setCurrentSearchParams] = useState<{
    [param: string]: string;
  }>();

  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setCurrentSearchParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      getLogs(currentSearchParams?.page ? Number(currentSearchParams?.page) : 1)
    );
  }, [currentSearchParams?.page]);

  const totalLogs = useSelector((state: AppStateType) => state.log.totalLogs);
  const logs = useSelector((state: AppStateType) => state.log.logs);

  const onPageChange = (current: number) => {
    setSearchParams({ ...currentSearchParams, page: `${current}` });
  };
  const getType = (color: "black" | "green" | "red") => {
    if (color === "black") {
      return undefined;
    } else if (color === "green") {
      return "success";
    } else if (color === "red") {
      return "danger";
    }
    return undefined;
  };
  return (
    <div style={{ padding: 20 }}>
      {logs.map((l) => (
        <div style={{ marginBottom: 10 }}>
          <Text type={getType(l.color)}>
            {l.createdAt} {l.text}
          </Text>
        </div>
      ))}
      <Pagination
        showTotal={(total) => `Total ${total} items`}
        pageSize={50}
        defaultCurrent={1}
        total={totalLogs}
        onChange={onPageChange}
      />
    </div>
  );
};
