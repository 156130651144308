import React from "react";
import { Skeleton } from "antd";

export const SitesPreloader = () => {
  return (
    <>
      <Skeleton.Button
        active={true}
        size={"small"}
        shape={"default"}
        block
        style={{ width: 300, marginBottom: 15 }}
      />
      <Skeleton.Button
        active={true}
        size={"small"}
        shape={"default"}
        block
        style={{ width: 300, marginBottom: 15 }}
      />
      <Skeleton.Button
        active={true}
        size={"small"}
        shape={"default"}
        block
        style={{ width: 300, marginBottom: 15 }}
      />
      <Skeleton.Button
        active={true}
        size={"small"}
        shape={"default"}
        block
        style={{ width: 300, marginBottom: 15 }}
      />
    </>
  );
};
