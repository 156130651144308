import {
  Breadcrumb,
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  FormProps,
  Input,
  Select,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import React, { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../store";
import {
  getAllTags,
  getOneTag,
  TagThunkType,
  updateTag,
} from "../store/actions/tagActions";
import { ITag } from "../types";

type FieldType = {
  tagName: string;
  fieldInDb: string;
  aiPromt?: string;
  imageSize?: string;
};

export const Tag = () => {
  const { Link } = Typography;
  const [form] = Form.useForm();
  const [isGenerateByAi, setIsGenerateByAi] = useState(false);
  const [isDepends, setIsDepends] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [tagsSelectItems, setTagsSelectItems] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedDependTag, setSelectedDependTag] = useState("");
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const { TextArea } = Input;
  const dispatch = useDispatch() as Dispatch<TagThunkType | AnyAction>;
  let { id } = useParams();
  const tag = useSelector((state: AppStateType) => state.tag.tag);
  const allTags = useSelector((state: AppStateType) => state.tag.allTags);

  const isTagsLoadingInProgress = useSelector(
    (state: AppStateType) => state.tag.isTagsLoadingInProgress
  );
  const isTagUpdateInProgress = useSelector(
    (state: AppStateType) => state.tag.isTagUpdateInProgress
  );
  useEffect(() => {
    if (id) {
      dispatch(getOneTag(id));
    }
  }, [id]);

  useEffect(() => {
    console.log("tag", tag);
    if (tag?._id) {
      form.setFieldValue("tagName", tag.tagName);
      form.setFieldValue("fieldInDb", tag.fieldInDb);
      form.setFieldValue("aiPromt", tag.aiPromt);
      form.setFieldValue("imageSize", tag.imageSize);
      if (tag.isGenerateByAi) {
        setIsGenerateByAi(true);
      }
      if (tag.isImage) {
        setIsImage(true);
      }
      if (tag.isDepends) {
        setIsDepends(true);
      }
      if (tag.dependsTags) {
        setTagsArray(tag.dependsTags);
      }
    }
  }, [tag?._id]);

  useEffect(() => {
    if (isDepends && allTags.length === 0) {
      dispatch(getAllTags());
    }
    if (allTags.length > 0) {
      let tagsFormatted = [] as { label: string; value: string }[];
      allTags.forEach((t) => {
        tagsFormatted.push({ label: t.tagName, value: t._id });
      });
      setTagsSelectItems(tagsFormatted);
    }
  }, [isDepends, allTags]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    if (tag?._id) {
      dispatch(
        updateTag(tag?._id, {
          ...values,
          isGenerateByAi: isGenerateByAi,
          isImage: isImage,
          isDepends: isDepends,
          dependsTags: tagsArray,
        } as ITag)
      );
    }
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsGenerateByAi(e.target.checked);
  };

  const onChangeIsImage: CheckboxProps["onChange"] = (e) => {
    setIsImage(e.target.checked);
  };

  const onChangeIsDepends: CheckboxProps["onChange"] = (e) => {
    setIsDepends(e.target.checked);
  };

  const onChangeDepend = (value: string) => {
    console.log(`selected ${value}`);

    setSelectedDependTag(value);
  };

  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        paddingTop: 10,
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Breadcrumb
          items={[
            {
              href: "/tags",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Tags</span>
                </>
              ),
            },
            {
              title: `Tag ${id}`,
            },
          ]}
        />
        {isTagsLoadingInProgress && <LoadingOutlined />}
      </div>

      <Form
        style={{
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
        }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          label="Tag name (e.g. company-name)"
          name="tagName"
          style={{ marginRight: 10 }}
        >
          <Input
            style={{ width: 250 }}
            size="middle"
            placeholder="Enter tag name"
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="Field name in site database"
          name="fieldInDb"
          style={{ marginRight: 10 }}
        >
          <Input
            style={{ width: 250 }}
            size="middle"
            placeholder="Enter field name"
          />
        </Form.Item>

        <Checkbox
          style={{ marginBottom: 10 }}
          onChange={onChange}
          checked={isGenerateByAi}
        >
          Generate by AI
        </Checkbox>
        {isGenerateByAi && (
          <>
            <Form.Item<FieldType> label="Promt for AI" name="aiPromt">
              <TextArea rows={4} placeholder="Enter promt for AI generation" />
            </Form.Item>
            <Checkbox
              style={{ marginBottom: 20 }}
              onChange={onChangeIsImage}
              checked={isImage}
            >
              <FileImageOutlined /> Image
            </Checkbox>

            {isImage && (
              <Form.Item<FieldType>
                label="Size (default 500px)"
                name="imageSize"
                style={{ marginRight: 10 }}
              >
                <Select
                  style={{ width: 350 }}
                  showSearch
                  placeholder="500px"
                  optionFilterProp="label"
                  options={[
                    {
                      label: "750px",
                      value: 750,
                    },
                    {
                      label: "500px",
                      value: 500,
                    },
                    {
                      label: "250px",
                      value: 250,
                    },
                  ]}
                />
              </Form.Item>
            )}
          </>
        )}

        <Checkbox
          style={{ marginBottom: 10 }}
          onChange={onChangeIsDepends}
          checked={isDepends}
        >
          Depends on other tags
        </Checkbox>

        {isDepends &&
          (isTagsLoadingInProgress ? (
            <LoadingOutlined style={{ marginLeft: 10, marginTop: 13 }} />
          ) : (
            <>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{ width: 350 }}
                  showSearch
                  placeholder="Select a tag"
                  optionFilterProp="label"
                  onChange={onChangeDepend}
                  options={tagsSelectItems}
                />
                <Link
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    setTagsArray([
                      ...tagsArray.filter((t) => t !== selectedDependTag),
                      selectedDependTag,
                    ])
                  }
                >
                  Add
                </Link>
              </div>
              <div style={{ marginBottom: 10, marginTop: 10 }}>
                {tagsArray.map((tag) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {allTags?.find((t) => t._id === tag)?.tagName}
                    </div>
                    <Button
                      onClick={() =>
                        setTagsArray([...tagsArray.filter((t) => t !== tag)])
                      }
                      style={{ width: 150, color: "red" }}
                      type="link"
                      size="middle"
                    >
                      Delete
                    </Button>
                  </div>
                ))}
              </div>
            </>
          ))}

        <Button
          style={{ width: 150, marginTop: 4 }}
          htmlType="submit"
          loading={isTagUpdateInProgress}
          type="primary"
          size="middle"
          disabled={false}
        >
          Save
        </Button>
      </Form>
    </div>
  );
};
