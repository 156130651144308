import { ThunkAction } from "redux-thunk";
import { AppStateType } from "..";
import {
  SET_IS_GETTING_SITES_IN_PROGRESS,
  SET_IS_CREATING_SITE_IN_PROGRESS,
  SET_SITES,
  SET_SORT_BY_FIELD,
  SET_SORT_BY,
  SET_PAGE,
  SET_SITES_TOTAL,
  SET_SEARCH_BY_NAME,
  SET_SEARCH_BY_STATE,
  SET_SEARCH_BY_CITY,
  SET_SITE,
  SET_IS_UPDATING_SITE_IN_PROGRESS,
} from "../reducers/siteReducer";
import { siteAPI } from "../../api";
import { ISite, SortByField } from "../../types";
import { FormInstance, notification } from "antd";

type SetIsGettingSitesInProgress = {
  type: typeof SET_IS_GETTING_SITES_IN_PROGRESS;
  payload: { isGettingSitesInProgress: boolean };
};

type SetIsCreatingSiteInProgress = {
  type: typeof SET_IS_CREATING_SITE_IN_PROGRESS;
  payload: { isCreatingSiteInProgress: boolean };
};

type SetIsUpdatingSiteInProgress = {
  type: typeof SET_IS_UPDATING_SITE_IN_PROGRESS;
  payload: { isUpdatingSiteInProgress: boolean };
};

type SetSites = {
  type: typeof SET_SITES;
  payload: { sites: ISite[] };
};

type SetSite = {
  type: typeof SET_SITE;
  payload: { site: ISite };
};

type SetSortByField = {
  type: typeof SET_SORT_BY_FIELD;
  payload: { sortByField: SortByField };
};

type SetSortBy = {
  type: typeof SET_SORT_BY;
  payload: { sortBy: "asc" | "desc" };
};

type SetPage = {
  type: typeof SET_PAGE;
  payload: { page: number };
};

type SetSitesTotal = {
  type: typeof SET_SITES_TOTAL;
  payload: { sitesTotal: number };
};

type SetSearchByName = {
  type: typeof SET_SEARCH_BY_NAME;
  payload: { serchByName: string };
};

type SetSearchByState = {
  type: typeof SET_SEARCH_BY_STATE;
  payload: { serchByState: string };
};

type SetSearchByCity = {
  type: typeof SET_SEARCH_BY_CITY;
  payload: { serchByCity: string };
};

export type SiteActionsTypes =
  | SetIsGettingSitesInProgress
  | SetIsCreatingSiteInProgress
  | SetSites
  | SetSortByField
  | SetSortBy
  | SetPage
  | SetSitesTotal
  | SetSearchByName
  | SetSearchByState
  | SetSearchByCity
  | SetSite
  | SetIsUpdatingSiteInProgress;

export const setIsGettingSitesInProgress = (
  isGettingSitesInProgress: boolean
): SetIsGettingSitesInProgress => ({
  type: SET_IS_GETTING_SITES_IN_PROGRESS,
  payload: { isGettingSitesInProgress },
});

export const setIsCreatingSiteInProgress = (
  isCreatingSiteInProgress: boolean
): SetIsCreatingSiteInProgress => ({
  type: SET_IS_CREATING_SITE_IN_PROGRESS,
  payload: { isCreatingSiteInProgress },
});

export const setIsUpdatingSiteInProgress = (
  isUpdatingSiteInProgress: boolean
): SetIsUpdatingSiteInProgress => ({
  type: SET_IS_UPDATING_SITE_IN_PROGRESS,
  payload: { isUpdatingSiteInProgress },
});

export const setSites = (sites: ISite[]): SetSites => ({
  type: SET_SITES,
  payload: { sites },
});

export const setSite = (site: ISite): SetSite => ({
  type: SET_SITE,
  payload: { site },
});

export const setSortByField = (sortByField: SortByField): SetSortByField => ({
  type: SET_SORT_BY_FIELD,
  payload: { sortByField },
});

export const setSortBy = (sortBy: "asc" | "desc"): SetSortBy => ({
  type: SET_SORT_BY,
  payload: { sortBy },
});

export const setPage = (page: number): SetPage => ({
  type: SET_PAGE,
  payload: { page },
});

export const setSitesTotal = (sitesTotal: number): SetSitesTotal => ({
  type: SET_SITES_TOTAL,
  payload: { sitesTotal },
});

export const setSearchByName = (serchByName: string): SetSearchByName => ({
  type: SET_SEARCH_BY_NAME,
  payload: { serchByName },
});

export const setSearchByState = (serchByState: string): SetSearchByState => ({
  type: SET_SEARCH_BY_STATE,
  payload: { serchByState },
});

export const setSearchByCity = (serchByCity: string): SetSearchByCity => ({
  type: SET_SEARCH_BY_CITY,
  payload: { serchByCity },
});

export type SiteThunkType = ThunkAction<
  Promise<void>,
  AppStateType,
  unknown,
  SiteActionsTypes
>;

export const getSites = (): SiteThunkType => async (dispatch, getState) => {
  try {
    const sortBy = getState().site.sortBy;
    const sortByField = getState().site.sortByField;
    const page = getState().site.page;
    const serchByName = getState().site.serchByName;
    const serchByState = getState().site.serchByState;
    const serchByCity = getState().site.serchByCity;
    dispatch(setIsGettingSitesInProgress(true));
    const response = await siteAPI.loadSites(
      sortBy,
      sortByField,
      page,
      serchByName,
      serchByState,
      serchByCity
    );
    if (response.status === "success") {
      dispatch(setSites(response.sites));
      dispatch(setSitesTotal(response.totalCount));
    }
  } catch (e) {
    notification.error({
      message: "Error",
      description: "Error loading list of sites",
    });
  } finally {
    dispatch(setIsGettingSitesInProgress(false));
  }
};

export const getOneSite =
  (id: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsGettingSitesInProgress(true));
      const response = await siteAPI.loadOneSite(id);
      if (response.status === "success") {
        dispatch(setSite(response.site));
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error loading site",
      });
    } finally {
      dispatch(setIsGettingSitesInProgress(false));
    }
  };

export const createSite =
  (
    name: string,
    company: string,
    state: string,
    county: string,
    statusAutoProgressOnCreate: string,
    form: FormInstance<any> | undefined
  ): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsCreatingSiteInProgress(true));
      const response = await siteAPI.newSite(
        name,
        company,
        state,
        county,
        statusAutoProgressOnCreate
      );
      console.log("response", response);
      if (response.status === "success") {
        dispatch(getSites());
        notification.success({
          message: "Success",
          description: "New sites successfully created",
        });
        form?.resetFields();
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while creating sites",
      });
    } finally {
      dispatch(setIsCreatingSiteInProgress(false));
    }
  };

export const updateSite =
  (
    values: { [param: string]: string },
    // error: boolean,
    // statusAutoProgressOnCreate: boolean,
    siteId: string
  ): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUpdatingSiteInProgress(true));
      const response = await siteAPI.updateSite(
        values,
        // error,
        // statusAutoProgressOnCreate,
        siteId
      );

      if (response.status === "success") {
        dispatch(getOneSite(siteId));
        notification.success({
          message: "Success",
          description: "Site updated successfully",
        });
      }
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while updating site",
      });
    } finally {
      dispatch(setIsUpdatingSiteInProgress(false));
    }
  };

export const deleteField =
  (fieldName: string, siteId: string): SiteThunkType =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsUpdatingSiteInProgress(true));
      const response = await siteAPI.deleteField(fieldName, siteId);

      if (response.status === "success") {
        notification.success({
          message: "Success",
          description: "Site updated successfully",
        });
      }
      dispatch(getOneSite(siteId));
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Error",
        description: "Error while updating site",
      });
    } finally {
      dispatch(setIsUpdatingSiteInProgress(false));
    }
  };
