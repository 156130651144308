import { Socket } from "socket.io-client";
import { IUser } from "../../types";
import { AuthActionsTypes } from "../actions/authActions";

export const SET_IS_AUTH = "auth/SET_IS_AUTH";
export const SET_AUTH_IN_PROGRESS = "auth/SET_AUTH_IN_PROGRESS";
export const SET_AUTH_ERROR_MESSAGE = "auth/SET_AUTH_ERROR_MESSAGE";
export const SET_USER_DATA = "auth/SET_USER_DATA";
export const SET_IS_APP_INIT = "auth/SET_IS_APP_INIT";
export const SET_SOCKET = "auth/SET_SOCKET";

const initialState = {
  isAuth: false,
  authInProgress: false,
  authErrorMessage: null as null | string,
  user: null as null | IUser,
  isAppInit: false,
  socket: null as null | Socket,
};

type InitialStateType = typeof initialState;

export const authReducer = (
  state = initialState,
  action: AuthActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_IS_AUTH:
    case SET_AUTH_IN_PROGRESS:
    case SET_AUTH_ERROR_MESSAGE:
    case SET_USER_DATA:
    case SET_IS_APP_INIT:
    case SET_SOCKET:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
